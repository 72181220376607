import "./tailwind.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { NuqsAdapter } from "nuqs/adapters/remix";
import { useChangeLanguage } from "remix-i18next/react";
import { getToast } from "remix-toast";
import { toast as notify } from "sonner";
import iconsHref from "/icons/icon.svg";
import DefaultErrorBoundary from "~/components/ui/error-boundary";
import { ProgressBar } from "./components/ui/progress-bar";
import { SonnerToaster } from "./components/ui/sonner";
import i18next from "./localization/i18n.server";
import { returnLanguageIfSupported } from "./localization/resource";
import { getRootUserData } from "./modules/auth/auth.server";
import { commitSession, getSession } from "./modules/auth/session.server";
import { combineHeaders, getDomainUrl } from "./utils";
import { ClientHintCheck, getHints } from "./utils/client-hint";
import { useNonce } from "./utils/nonce-provider";

export const links: LinksFunction = () => [
  { rel: "prefetch", href: iconsHref, as: "image", type: "image/svg+xml" },
  {
    rel: "preconnect",
    href: "https://fonts.googleapis.com",
  },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap",
  },
];

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  const { toast, headers: toastHeaders } = await getToast(request);
  const user = await getRootUserData(request);
  const lang = returnLanguageIfSupported(params.lang);
  let locale = lang ?? (await i18next.getLocale(request)) ?? "pt-BR";

  if (user && "shouldCommitSession" in user && user.shouldCommitSession) {
    const authSession = await getSession(request.headers.get("Cookie"));
    const { shouldCommitSession, ...userWithoutShouldCommit } = user;

    // Set the entire user object in the session
    authSession.set("session", userWithoutShouldCommit);

    const cookie = await commitSession(authSession);

    return json(
      {
        user,
        locale,
        requestInfo: {
          hints: getHints(request),
          origin: getDomainUrl(request),
          path: new URL(request.url).pathname,
        },
        toast,
      },
      {
        headers: combineHeaders([["Set-Cookie", cookie], ...toastHeaders]),
      }
    );
  }

  return json(
    {
      user,
      locale,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
      },
      toast,
    },
    {
      headers: combineHeaders(toastHeaders),
    }
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  const { locale, toast, user } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();
  useChangeLanguage(locale);
  const nonce = useNonce();

  useEffect(() => {
    switch (toast?.type) {
      case "success":
        notify.success(toast.message, {
          description: toast.description,
        });
        return;
      case "error":
        notify.error(toast.message, {
          description: toast.description,
        });
        return;
      default:
        return;
    }
  }, [toast]);

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Links />
        {/* Facebook Pixel Code */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', 'your-pixel-id');
              fbq('track', 'PageView');
            `,
          }}
        />
      </head>
      <body>
        {user && "isImpersonating" in user && user.isImpersonating && (
          <div style={{ background: "yellow", padding: "10px" }}>
            You are currently impersonating {user.email}
          </div>
        )}
        {children}
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              if (typeof window !== 'undefined') {
                window.fbAsyncInit = function() {
                  FB.init({
                    appId: '1034349278262009',
                    cookie: true,
                    xfbml: true,
                    version: 'v20.0'
                  });
                };
              }
            `,
          }}
        />
        <script
          async
          defer
          crossOrigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js"
        />
        <SonnerToaster closeButton richColors />
        <ProgressBar />
      </body>
    </html>
  );
}

function App() {
  return (
    <NuqsAdapter>
      <Outlet />
    </NuqsAdapter>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <DefaultErrorBoundary />;
}

export function HydrateFallback() {
  return <h1>Loading...</h1>;
}
